<template>
    <transition name="fade">
        <TranslatedForm
            v-if="item"
            v-slot="{ editingLang }"
        >
            <form
                class="form-2 spaced"
                @submit.prevent=""
            >
                <fieldset
                    v-if="editingLang === shared.session.company.company_languages.default.key"
                    class="switch-label"
                >
                    <HtFormSwitch
                        :id="'is-enable-notif'"
                        v-model="item.resource.programs[0].is_enabled"
                        :label="translate('Enable this notification')"
                        :on-label="translate('On')"
                        :off-label="translate('Off')"
                        :name="'is-enable-notif'"
                        :show-optional="false"
                        class="mb-0"
                    />
                </fieldset>

                <HtFormInput
                    id="name"
                    ref="nameInput"
                    v-model="item.resource.translations.name[editingLang]"
                    v-validate.continues="{
                        translation_default: [
                            item.resource.translations.name,
                            shared.session.company.company_languages.default.key,
                        ],
                        max:255,
                    }"
                    name="name"
                    :label="translate('Name')"
                    :data-vv-as="translate('name')"
                    :cypress="'email-field-name'"
                />

                <LockedToggle
                    v-if="$can('AbstractManageLockedResources')"
                    v-model="item.is_locked"
                />

                <HtProgramList
                    ref="htProgramListInput"
                    v-model="item.resource.programs[0].company_program_id"
                    :required="false"
                    :with-default="false"
                    :disabled="disabledProgramList"
                    @input="onProgramSelect()"
                />

                <fieldset class="medium">
                    <HtFormInput
                        :id="'subject'"
                        ref="subjectInput"
                        v-model="item.translations.subject[editingLang]"
                        v-validate.continues="{
                            translation_default: [
                                item.translations.subject,
                                shared.session.company.company_languages.default.key,
                            ],
                        }"
                        :name="'subject'"
                        :label="translate('Subject')"
                        :data-vv-as="translate('e-mail subject')"
                        :variables="subject_variables"
                        cypress="email-subject"
                    />
                </fieldset>

                <fieldset
                    class="medium"
                    :class="{ error: errors.has('content') }"
                >
                    <HtAIFormEditor
                        v-if="isAIEnabled"
                        :id="'content'"
                        ref="contentInput"
                        v-model="item.translations.content[editingLang]"
                        v-validate.continues="{
                            translation_default: [
                                item.translations.content,
                                shared.session.company.company_languages.default.key,
                            ],
                        }"
                        :name="'content'"
                        :label="translate('Content')"
                        :variables="content_variables"
                        cypress="email-content"
                        resource-openai="email"
                        :suggestions="suggestions"
                    />
                    <HtFormEditor
                        v-else
                        :id="'content'"
                        ref="contentInput"
                        v-model="item.translations.content[editingLang]"
                        v-validate.continues="{
                            translation_default: [
                                item.translations.content,
                                shared.session.company.company_languages.default.key,
                            ],
                        }"
                        :name="'content'"
                        :label="translate('Content')"
                        :variables="content_variables"
                        cypress="email-content"
                    />
                </fieldset>

                <fieldset class="medium">
                    <HtFormEditor
                        :id="'signature'"
                        ref="signatureInput"
                        v-model="item.translations.signature[editingLang]"
                        v-validate.continues="{
                            translation_default: [
                                item.translations.signature,
                                shared.session.company.company_languages.default.key,
                            ],
                        }"
                        :name="'signature'"
                        :label="translate('Signature')"
                        :variables="signature_variables"
                        cypress="email-signature"
                    />
                </fieldset>

                <fieldset>
                    <HtFormSwitch
                        id="file-join"
                        v-model="isFileBoxChecked[editingLang]"
                        :label="translate('Attach a file')"
                        :off-label="translate('No')"
                        :on-label="translate('Yes')"
                        name="file-join"
                        cypress="email-attach-file"
                    />

                    <transition name="fade">
                        <div v-if="isFileBoxChecked[editingLang]">
                            <TranslatableFiles
                                :files="item.file_translations"
                                :errors-messages="errors"
                                :editing-lang="editingLang"
                                :validation-rules="{
                                    translation_default:[
                                        item.file_translations,
                                        shared.session.company.company_languages.default.key,
                                    ]
                                }"
                                @input="v => item.file_translations = v"
                            />
                        </div>
                    </transition>
                </fieldset>

                <fieldset>
                    <HtFormUserOrRoleSelect
                        :id="'sender'"
                        v-model="getSenderFromEmail"
                        v-validate.disable="'required'"
                        :data-vv-as="translate('sender')"
                        :label="translate('Sender')"
                        :name="'sender'"
                        :type="getTypeOfSender"
                        :show-asterisk="true"
                        cypress="email-sender"
                        @on-add="openAddSenderModal"
                        @on-remove="onRemoveSender"
                    />
                </fieldset>

                <section v-if="isResourceTargeted">
                    <fieldset :class="[{ error: errors.has('email_participants') }, 'medium']">
                        <label for="email_participants">
                            <t>Recipients</t>
                            *
                        </label>
                        <transition-group
                            name="fade"
                            class="card-6 flex-container"
                        >
                            <template
                                v-if="item.resource.programs[0].participants && item.resource.programs[0].participants.length"
                            >
                                <div
                                    v-for="(participant, index) in emailParticipants"
                                    :key="index"
                                    style="display:flex"
                                    class="item"
                                >
                                    <UserBlock2
                                        v-if="participant.company_role && participant.company_role_id"
                                        :key="`${index}_role`"
                                        v-model="participant.company_role"
                                        :type="'role'"
                                        @onRemove="onRemoveParticipant(participant)"
                                    />
                                    <UserBlock2
                                        v-else-if="participant.company_user && participant.company_user_id"
                                        :key="`${index}_user`"
                                        v-model="participant.company_user"
                                        :type="'user'"
                                        @onRemove="onRemoveParticipant(participant)"
                                    />
                                    <UserBlock2
                                        v-else-if="participant.company_group && participant.company_group_id"
                                        :key="`${index}_group`"
                                        v-model="participant.company_group"
                                        :type="'group'"
                                        @onRemove="onRemoveParticipant(participant)"
                                    />
                                </div>
                            </template>
                            <UserBlock2
                                ref="email_participantsInput"
                                :key="-1200"
                                data-cy="email-field-company_email_participant"
                                @onAdd="openAddParticipantsModal"
                            />
                        </transition-group>
                        <input
                            id="email_participants"
                            v-validate.disable="'required|array'"
                            v-next-field
                            type="hidden"
                            :value="item.resource.programs[0].participants && item.resource.programs[0].participants.length ? '-' : ''"
                            name="email_participants"
                            class="full-width"
                            :data-vv-as="translate('list of participants')"
                            :placeholder="translate('Type here')"
                        >
                        <div
                            v-for="(errorMessage, index) in errors.collect('email_participants')"
                            :key="index"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </div>
                    </fieldset>

                    <HtFormGroup
                        :label="translate('Filters')"
                        :description="
                            translate(
                                'Add filters to decide who can access the resource'
                            )
                        "
                    >
                        <HtFormCard>
                            <ProgramResourceFilters
                                v-if="item.resource.programs[0].company_program_id"
                                v-model="item.resource.programs[0].filters"
                                :program-id="item.resource.programs[0].company_program_id"
                                :use-resource-perimeter="useResourcePerimeter"
                            />
                            <div
                                v-else
                                class="sentence-filter"
                            >
                                <t>
                                    Select a program to display associated filters
                                </t>
                            </div>
                        </HtFormCard>
                    </HtFormGroup>

                    <HtFormCard class="mb-5">
                        <RetroactivityToggle
                            :is-retroactive.sync="item.is_retroactive"
                            :send-notif.sync="item.send_retroactive_notif"
                            :resource="'company_email_custom'"
                            :affected-programs="affectedPrograms"
                            :loading="affectedProgramsLoading"
                        />
                    </HtFormCard>

                    <HtKeyDatesInputOrder
                        :end-tooltip="translate('This email will be sent on the selected timeline')"
                        :program-id="item.resource.programs[0].company_program_id"
                        :resource-id="item.id"
                        :resource-type="resourceableClass"
                        :filters="item.company_program_resource_filter"
                        :is-business-day.sync="item.resource.programs[0].is_business_day"
                        :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                        :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                        :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                        :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                        :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                        :dependents.sync="item.resource.programs[0].dependents"
                        :dependencies.sync="item.resource.programs[0].dependencies"
                    />
                </section>
            </form>

            <div class="modal-actions right">
                <Button
                    v-show="Boolean(id)"
                    :state="buttonState"
                    class="negative"
                    cypress="email-delete"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    :state="buttonState"
                    cypress="email-add"
                    @click="onValidate()"
                >
                    <t>{{ item.id ? 'Save' : 'Add' }}</t>
                </Button>
            </div>

            <modalable
                ref="senderModal"
                class="modalable-1 small no-padding-bottom"
                :options="{ title: translate('Add sender') }"
            >
                <SearchRolePanel
                    ref="senderRoleList"
                    :multiple-mode="false"
                    :is_assignable_onboardee="false"
                    @onValidate="$refs.senderModal.close()"
                />
            </modalable>

            <modalable
                ref="participantsModal"
                class="modalable-1 small no-padding-bottom"
                :options="{ title: translate('Add recipients') }"
            >
                <SearchRolePanel
                    ref="participantsRoleList"
                    :multiple-mode="true"
                    :has-company-group="true"
                    :is_assignable_onboardee="true"
                    @onValidate="$refs.participantsModal.close()"
                />
            </modalable>
        </TranslatedForm>
    </transition>
</template>

<script>
import api from '@/store/api';
import EmailCustomVariable from '@/components/mixins/EmailCustomVariable';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import I18n from '@/modules/i18n/I18n';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import LockedToggle from '@/components/globals/LockedToggle.vue';
import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';
import RetroactivityToggle from '@/components/globals/RetroactivityToggle.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';
import { HTTP_RESPONSE_UNPROCESSABLE_ENTITY } from '@/plugins/HttpPlugin';
import { mapGetters } from 'vuex';
import CompanyEmailTemplate from '@/models/CompanyEmailTemplate';
import CompanyEntityValueCustom from '@/models/CompanyEntityValueCustom';

export default {
    name: 'ResourceEmailEdit',
    components: {
        HtKeyDatesInputOrder,
        HtFormCard,
        HtFormSwitch,
        ProgramResourceFilters,
        TranslatedForm,
        TranslatableFiles,
        HtProgramList,
        HtFormUserOrRoleSelect,
        LockedToggle,
        RetroactivityToggle,
    },

    mixins: [
        DefaultFiltersMixin,
        EmailCustomVariable,
        DefaultOrderCompletionMixin,
        ResourceKeyDateLabelMixin,
    ],

    inject: ['modal'],
    props: {
        id: {
            type: [String, Number],
            required: false,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        showButtonDelete: {
            type: Boolean,
            default: true,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            item: null,
            isFileBoxChecked: {},
            currentLanguage: this.shared.session.company.company_language,
            affectedPrograms: 0,
            affectedProgramsLoading: false,
            targetedProgramWithCustomEntities: [],
            buttonState: 'idle',
            programFromStore: null,
            suggestions: [
                { value: this.translate('Invite the manager to prepare an interview with the employee') },
                { value: this.translate('Welcome an employee') },
                { value: this.translate('Employee satisfaction survey') },
                { value: this.translate('Inform about a policy update') },
                { value: this.translate('Provide performance feedback to an employee') },
                { value: this.translate('Announce a company-wide event') },
                { value: this.translate('Address a change in management') },
                { value: this.translate('Send a holiday greetings message') },
                { value: this.translate('Request employee feedback on a new initiative') },
            ],
        };
    },

    computed: {
        ...mapGetters('programs', [
            'findProgramById',
        ]),

        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanyEmailCustomTemplate');
        },

        isAIEnabled() {
            return this.shared.session.company.company_knowledge_settings.is_ai_feature_enabled === true
                && this.shared.session.company.company_knowledge_settings.is_ai_bot_enabled === true;
        },
        resourceableClass() {
            return CompanyEmailCustomTemplate.RESOURCEABLE_CLASS;
        },
        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },
        labelTitle() {
            return I18n.translate(this.id ? 'Edit email' : 'Add email');
        },
        labelButton() {
            return this.translate(!this.item.id ? 'Add' : 'Save');
        },
        canDelete() {
            return this.showButtonDelete === true && this.item.id;
        },
        getSenderFromEmail() {
            if (this.item.sender_user_id) {
                return this.item.sender_user;
            }

            if (this.item.sender_role_id) {
                return this.item.sender_role;
            }

            return null;
        },
        getTypeOfSender() {
            return this.item.sender_role_id ? 'role' : 'user';
        },
        emailParticipants() {
            const participants = [];

            for (const participant of this.item.resource.programs[0].participants) {
                if (
                    (!participant.company_role && !participant.company_user && !participant.company_group)
                    && (!participant.role && !participant.user && !participant.group)
                ) {
                    continue;
                }

                participant.company_role = participant.company_role || participant.role;
                participant.company_user = participant.company_user || participant.user;
                participant.company_group = participant.company_group || participant.group;
                participants.push(participant);
            }

            return participants;
        },
    },
    watch: {
        item(newVal) {
            if (newVal) {
                this.shared.session.company.company_languages.enabled.forEach((language) => {
                    this.isFileBoxChecked[language.key] = Boolean(newVal.file_translations[language.key]);
                });
            }
        },
        'item.is_retroactive': function () {
            this.getUserProgramsAffected();
        },
    },

    async created() {
        this.shared.session.company.company_languages.enabled.forEach((language) => {
            this.$set(this.isFileBoxChecked, language.key, false);
        });

        if (this.isDuplicableModel) {
            await this.duplicateModel();
        } else if (this.id) {
            await this.createModelFromId();
            await this.fetchUserAffectedPrograms();
        } else {
            this.createModelFromCollection();
            await this.fetchUserAffectedPrograms();
        }

        const isNew = (!this.item || !this.item.id);
        if (!this.isDuplicableModel && isNew) {
            this.setDefaultSignature();
        }

        this.modal.setTitle(this.labelTitle);

        // Custom Entities
        await this.setTargetedProgramWithCustomEntitiesFromItemResource();
        if (this.isTargetedProgramWithCustomEntitiesEmpty()) {
            await this.setTargetedProgramWithCustomEntitiesFromStore();
        }

        if (this.isTargetedProgramWithCustomEntitiesEmpty()) {
            return;
        }

        await this.getCustomEntityVariables();

        // note: In some cases, custom entities are not instantiated, so we need to prepare them.
        this.targetedProgramWithCustomEntities = this.prepareCustomEntitiesData(
            this.item?.resource?.programs?.[0]?.program?.customEntities,
        )
            ?.flat(Infinity) ?? [];

        this.hydrateTargetedProgramWithCustomEntities();
    },

    beforeDestroy() {
        this.$eventHub.$off('filter-change', this.getUserProgramsAffected);
        this.$eventHub.$off('program-change', this.getUserProgramsAffected);
    },

    methods: {
        setTargetedProgramWithCustomEntitiesFromItemResource() {
            // @see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
            this.targetedProgramWithCustomEntities = this.item?.resource?.programs
                ?.flatMap((resourceProgram) => resourceProgram?.program?.customEntities?.map((customEntity) => ({
                    slug: customEntity.entity?.slug,
                    name: customEntity.entity?.name,
                })) || [])
                ?.filter(Boolean);
        },
        isTargetedProgramWithCustomEntitiesEmpty() {
            return this.targetedProgramWithCustomEntities.length <= 0;
        },
        async setTargetedProgramWithCustomEntitiesFromStore() {
            if (this.programFromStore === null) {
                await this.setProgramFromStore();
            }

            this.targetedProgramWithCustomEntities = this.programFromStore?.customEntities?.map((customEntity) => ({
                slug: customEntity.entity.slug,
                name: customEntity.entity.name,
            }));
        },
        async setProgramFromStore() {
            // note: to get the custom entities from a new program we need to refresh the store after the targeted step
            await this.$store.dispatch('programs/fetchPrograms', { forceRefresh: true });
            this.programFromStore = this.findProgramById(this.companyProgramId);
        },
        getCustomEntityVariables() {
            this.targetedProgramWithCustomEntities.forEach((targetedProgramWithCustomEntity) => {
                const customEntityVariableName = targetedProgramWithCustomEntity.slug;
                const customEntityVariable = {
                    name: `${CompanyEntityValueCustom.VARIABLE_SLUG_PREFIX}${customEntityVariableName}`,
                    label: targetedProgramWithCustomEntity.name,
                };

                this.subject_variables.push(customEntityVariable);
                this.content_variables.push(customEntityVariable);
                this.signature_variables.push(customEntityVariable);
            });
        },
        async createModelFromId() {
            this.item = {
                ...await this.fetchModelFromId(),
                is_retroactive: false,
                send_retroactive_notif: false,
            };
        },
        async fetchModelFromId() {
            const email = (await api.configuration.emails.get(this.id)).data.data;

            const isResourceTargeted = email.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                email.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            return email;
        },
        async duplicateModel() {
            this.item = await this.fetchModelFromId();
            this.item.id = undefined;
            this.item.resource.id = null;
            this.item.resource.programs[0].id = null;
            this.item.resource.programs[0].company_program_id = this.companyProgramId;
            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].filters = [];
            this.item.resource.programs[0].dependents = [];
            this.item.resource.programs[0].dependencies = [];
            this.item.resource.programs[0].participants = [];

            this.item.resource.programs[0].participants = this.item.resource.programs[0].participants.map((participant) => ({
                ...participant,
                id: undefined,
            }));
        },
        async onValidate() {
            // to be sure we can validate the default lang
            this.currentLanguage = this.shared.session.company.company_language;

            await this.$validator.validateAll();

            if (this.errors.any()) {
                this.$Utils.scrollToFirstError(this);
                return;
            }

            this.buttonState = 'loading';

            const resourceToSave = cloneDeep(this.item);
            if (!resourceToSave.resource.programs[0].company_program_id) {
                resourceToSave.resource.programs = [];
            }

            this.shared.session.company.company_languages.enabled.forEach((language) => {
                if (!this.isFileBoxChecked[language.key]) {
                    delete resourceToSave.file_translations[language.key];
                }
            });

            try {
                if (this.item.id) {
                    await api.configuration.emails.update(this.item.id, resourceToSave);
                } else {
                    await api.configuration.emails.store(resourceToSave);
                }
                this.$emit('onSave');
                this.modal.close();
            } catch (exception) {
                this.buttonState = 'idle';

                const responseStatus = window.parseInt(response.response.status, 10);
                if (responseStatus !== HTTP_RESPONSE_UNPROCESSABLE_ENTITY) {
                    this.$Notifier('App')
                        .showError(this.translate('An error occured'));
                }
            }
        },
        async onDelete() {
            const shouldDelete = await this.modal.deleteWindow({
                content: this.translate(
                    'Are you sure you want to delete this email? Once you click on delete, you will no longer be able to access this email.',
                ),
            });
            if (!shouldDelete) {
                return;
            }
            this.buttonState = 'loading';
            await api.configuration.emails.delete(this.item.id);
            this.buttonState = 'idle';
            this.$emit('onDelete');
            this.modal.close();
        },
        openAddParticipantsModal() {
            this.$refs.participantsRoleList.init({
                onValidate: (participants) => {
                    this.item.resource.programs[0].participants = participants;
                },
                selected: this.item.resource.programs[0].participants,
            });
            this.$refs.participantsModal.open();
        },
        onRemoveParticipant(participantToRemove) {
            const index = this.item.resource.programs[0].participants.findIndex((participant) => {
                if (participant.company_user_id) {
                    return participant.company_user_id === participantToRemove.company_user_id;
                }

                if (participant.company_role_id) {
                    return participant.company_role_id === participantToRemove.company_role_id;
                }

                if (participant.company_group_id) {
                    return participant.company_group_id === participantToRemove.company_group_id;
                }
            });

            this.item.resource.programs[0].participants.splice(index, 1);
        },
        openAddSenderModal() {
            this.$refs.senderRoleList.init({
                onSelect: (_item) => {
                    if (_item.company_user_id) {
                        this.item = {
                            ...this.item,
                            sender_user: _item.company_user,
                            sender_user_id: _item.id,
                            sender_role_id: null,
                        };
                    } else {
                        this.item = {
                            ...this.item,
                            sender_role: _item.company_role,
                            sender_user_id: null,
                            sender_role_id: _item.id,
                        };
                    }
                    this.$refs.senderModal.close();
                },
            });
            this.$refs.senderModal.open();
        },
        onRemoveSender() {
            this.item.sender_user_id = null;
            this.item.sender_role_id = null;
        },
        async getUserProgramsAffected() {
            if (this.item.is_retroactive === false) return;
            this.affectedProgramsLoading = true;
            const resourceProgram = this.isResourceTargeted ? this.item.resource.programs[0] : {};
            const programIds = this.isResourceTargeted ? [resourceProgram.company_program_id] : [];
            const entities = this.isResourceTargeted ? resourceProgram.filters.map((filter) => ({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            })) : [];

            this.$http.post('affected-programs-retroactivity', {
                task_type: CompanyUserProgramTask.TASK_COMPANY_USER_EMAIL_CUSTOM,
                resource_id: this.id,
                entities,
                program_ids: programIds,
            })
                .then((response) => {
                    this.affectedPrograms = response.data.count;
                })
                .finally(() => {
                    this.affectedProgramsLoading = false;
                });
        },
        async fetchUserAffectedPrograms() {
            this.getUserProgramsAffected();
            this.$eventHub.$on('filter-change', this.getUserProgramsAffected);
            this.$eventHub.$on('program-change', this.getUserProgramsAffected);
        },
        createModelFromCollection() {
            this.item = {
                is_retroactive: false,
                send_retroactive_notif: false,
                sender_role_id: null,
                sender_user_id: null,
                translations: {
                    subject: {},
                    content: {},
                    signature: {},
                },
                file_translations: {},
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
            };

            if (this.useResourcePerimeter) {
                this.item.resource.programs[0].filters = this.userPerimeterEntities;
            }
        },
        setDefaultSignature() {
            this.shared.session.company.company_languages.enabled.forEach((companyLanguage) => {
                const signatures = {
                    [CompanyLanguage.KEY_FR]: CompanyEmailCustomTemplate.DEFAULT_SIGNATURE_FR,
                    [CompanyLanguage.KEY_EN]: CompanyEmailCustomTemplate.DEFAULT_SIGNATURE_EN,
                    [CompanyLanguage.KEY_DE]: CompanyEmailCustomTemplate.DEFAULT_SIGNATURE_DE,
                    [CompanyLanguage.KEY_IT]: CompanyEmailCustomTemplate.DEFAULT_SIGNATURE_IT,
                };

                if (!this.item.translations.signature[companyLanguage.key]) {
                    const signature = signatures[companyLanguage.key] ?? null;
                    this.$set(this.item.translations.signature, companyLanguage.key, signature);
                }
            });
        },
        async onProgramSelect() {
            if (this.id !== null) return;

            this.removeCustomEntitiesVariables();

            const programId = this.item?.resource?.programs?.[0]?.company_program_id ?? null;
            if (programId === null) return;

            api.configuration.programs.getProgram(programId).then((response) => {
                this.programFromStore = response.data;
                this.setTargetedProgramWithCustomEntitiesFromStore();
                this.hydrateTargetedProgramWithCustomEntities();
            });
        },
        removeCustomEntitiesVariables() {
            this.subject_variables = this.subject_variables.filter((ce) => !ce.name.includes(CompanyEmailTemplate.CUSTOM_ENTITY_VARIABLES_PREFIX));
            this.content_variables = this.content_variables.filter((ce) => !ce.name.includes(CompanyEmailTemplate.CUSTOM_ENTITY_VARIABLES_PREFIX));
            this.signature_variables = this.signature_variables.filter((ce) => !ce.name.includes(CompanyEmailTemplate.CUSTOM_ENTITY_VARIABLES_PREFIX));
        },
        prepareCustomEntitiesData(data) {
            return data?.map((customEntity) => ({
                slug: customEntity.entity?.slug,
                name: customEntity.entity?.name,
            }));
        },
        hydrateTargetedProgramWithCustomEntities() {
            if (this.targetedProgramWithCustomEntities.length > 0) {
                this.targetedProgramWithCustomEntities.forEach((targetedProgramWithCustomEntity) => {
                    const customEntityVariableName = targetedProgramWithCustomEntity.slug;
                    const customEntityVariable = {
                        name: `${CompanyEmailTemplate.CUSTOM_ENTITY_VARIABLES_PREFIX}${customEntityVariableName}`,
                        label: targetedProgramWithCustomEntity.name,
                    };

                    if (!this.hasAlreadyCustomEntityAsVariable(this.subject_variables, customEntityVariable.name)) {
                        this.subject_variables.push(customEntityVariable);
                    }
                    if (!this.hasAlreadyCustomEntityAsVariable(this.content_variables, customEntityVariable.name)) {
                        this.content_variables.push(customEntityVariable);
                    }
                    if (!this.hasAlreadyCustomEntityAsVariable(this.signature_variables, customEntityVariable.name)) {
                        this.signature_variables.push(customEntityVariable);
                    }
                });
            }
        },
        hasAlreadyCustomEntityAsVariable(variables, variableToCheck) {
            return variables.find((variable) => variable.name.includes(variableToCheck));
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-library .item-content {
    min-height: 198px !important;
}
</style>
